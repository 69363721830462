import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Content } from "page_components/notices"

const Notices = ({ data, location }) => {
  const title = "Ogłoszenia"
  const allNotices = data.allWpNotice.nodes

  return (
    <Layout location={location} seo={{ title: title }} rmLangSwitcher>
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Content allNotices={allNotices} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpNotice(filter: { language: { code: { eq: PL } } }) {
      nodes {
        id
        title
        content
        date
        acfNotices {
          file {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default Notices
