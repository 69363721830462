import "./styles.scss"

import React from "react"

import { useLocale } from "context/locale"

import IcoDownload from "assets/icons/download.svg"

const Content = ({ allNotices }) => {
  const { t } = useLocale()

  return (
    <section className="notices-content">
      <div className="container-fluid">
        {allNotices.length > 0 ? (
          allNotices?.map((item, index) => (
            <div className="notices-content__item" key={index}>
              <div className="row">
                <div className="col-md-2">
                  <date>
                    {item.date.split("T")[0].split("-").reverse().join(".")}
                  </date>
                </div>
                <div className="col-md-8">
                  <h4>{item.title}</h4>
                  <div dangerouslySetInnerHTML={{ __html: item.content }} />
                  {item?.acfNotices?.file?.localFile?.publicURL && (
                    <>
                      <br />
                      <a
                        href={item?.acfNotices?.file?.localFile?.publicURL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span>
                          <img src={IcoDownload} alt="" />
                        </span>
                        {t("zobacz dokument")}
                      </a>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>{t("Brak ogłoszeń.")}</p>
        )}
      </div>
    </section>
  )
}

export default Content
