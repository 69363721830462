import "./styles.scss"

import React from "react"

const PageHeader = ({ title, button, smaller }) => {
  return (
    <section
      className={`page-header page-header--${title
        .toLowerCase()
        .replace(" ", "-")}${button ? " page-header--button" : ""}${
        smaller ? " page-header--smaller" : ""
      }`}
    >
      <div className="container-fluid">
        <h1>
          {title} {button && button}
        </h1>
      </div>
    </section>
  )
}

export default PageHeader
